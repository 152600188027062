.typed-text {
    position: relative;
    display: inline-block; /* Ensure inline behavior */
}

.typed-text::after {
    content: "|"; /* The cursor symbol, you can also use a block if preferred */
    position: absolute;
    right: '20px';
    font-weight:100;
    animation: blink 0.8s infinite;
}

@keyframes blink {
    0%, 49% {
        opacity: 1;
    }
    50%, 100% {
        opacity: 0;
    }
}
